import { msalConfig } from "../authConfig";
import xhr from "./xhr";

import {
  PublicClientApplication,
  PopupRequest,
  EventType,
  InteractionRequiredAuthError,
  IPublicClientApplication,
} from "@azure/msal-browser";

export let loginRequest: PopupRequest = {
  scopes: [
    "https://zylincprovisioningtest.onmicrosoft.com/provisioning/api/all.read",
  ],
};

let baseURL = "api/accountportal";
if (process.env.NODE_ENV === "development") {
  console.log("In development mode");
  baseURL = `https://provisioningtest.zylinc.cloud/${baseURL}`;
  //baseURL = `https://account.zylinc.cloud/${baseURL}`;
}

const acquireToken = async (pca: IPublicClientApplication) => {
  const account = pca.getActiveAccount();
  if (account) {
    const req = { ...loginRequest, account };
    return await pca.acquireTokenSilent(req).catch((e) => {
      if (e instanceof InteractionRequiredAuthError) {
        return pca.acquireTokenRedirect(loginRequest);
      }
    });
  }
  return pca.acquireTokenRedirect(loginRequest);
};

const context = fetch(`${baseURL}/configuration`)
  .then((res) => res.json())
  .then(
    async (config: {
      ApiScope: string;
      AzureAdTenantName: string;
      ClientId: string;
      RedirectUri: string;
      Authority: string;
    }) => {
      console.log("Config:", config);
      msalConfig.auth.clientId = config.ClientId;
      msalConfig.auth.authority = config.Authority;
      msalConfig.auth.knownAuthorities = [
        `${config.AzureAdTenantName}.b2clogin.com`,
      ];
      msalConfig.auth.redirectUri = config.RedirectUri;
      //msalConfig.auth.redirectUri = "http://localhost:5000";
      loginRequest.scopes = [config.ApiScope];

      const pca = new PublicClientApplication(msalConfig);

      if (!pca.getActiveAccount() && pca.getAllAccounts().length > 0) {
        pca.setActiveAccount(pca.getAllAccounts()[0]);
      }

      // Listen and handle login events
      pca.addEventCallback((event) => {
        if (
          [
            EventType.LOGIN_SUCCESS,
            EventType.ACQUIRE_TOKEN_SUCCESS,
            EventType.SSO_SILENT_SUCCESS,
            // @ts-ignore
          ].includes(event.eventType) &&
          event.payload
        ) {
          // @ts-ignore
          const account = event.payload.account;
          pca.setActiveAccount(account);
        }

        // in case user loggs out one of his accounts, make sure you refresh the active one ?
        if (event.eventType === EventType.LOGOUT_SUCCESS) {
          // don't look for other active accounts rather prompt the user for another login
          // if (pca.getAllAccounts().length > 0) {
          //   pca.setActiveAccount(pca.getAllAccounts()[0]);
          // }
        }

        if (event.eventType === EventType.LOGIN_FAILURE) {
          // :O
          console.log(JSON.stringify(event));
        }
      });
      return pca.initialize().then(() => pca);
    }
  )
  .then((pca) => {
    //TODO: set xhr baseURL based on configuraion and env
    xhr.interceptors.request.use(
      async function (config) {
        const res = await acquireToken(pca);
        // @ts-ignore
        const { accessToken = "not found" } = res;
        config.headers.set("Authorization", `Bearer ${accessToken}`);
        // config.headers.set(
        //   "X-API-Key",
        //   "CUYEINxP.umnfe6BmhoDgTPcmUe7AvB4wzvAvI6TYpBHRL0ImzbPG3MVjig7SNuKqEwY60MeQ"
        // );

        return config;
      },

      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
    xhr.defaults.baseURL = `${baseURL}/v1`;
    return pca;
  });

export default context;
