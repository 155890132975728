import xhr from "../../services/xhr";
import { TenantDetails } from "../../types/TenantDetails";
import { useParams } from "react-router-dom";
import { QuotaLicense } from "../../types/License";
import { ChangeEvent, useRef, useState } from "react";

import useProductsFormData, {
  ProductsFormData,
} from "../../hooks/useProductsFormData";

import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";

import {
  Alert,
  Box,
  Button,
  Divider,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

const StyledDivider = () => (
  <Divider sx={{ marginTop: 1, maxWidth: "550px" }} />
);

const processNumericInput = (value: string) => {
  if (value.length === 0) return 0;
  if (value === "0") return null;
  if (value[0].match(/[1-9]/gm)) return null;
  if (value[0] === "-") return 0;
  return parseInt(value);
};

const LicenseProfiles = ({ isAdmin }: { isAdmin: boolean }) => {
  const { control, register, setValue } = useFormContext<ProductsFormData>();

  const { fields } = useFieldArray({
    control,
    name: "LicenseProfiles",
  });

  return (
    <Stack direction={"column"} gap={3} flexGrow={1}>
      <Typography variant={"h5"} color={"primary.main"}>
        {"License profiles"}
      </Typography>
      <StyledDivider />
      {(isAdmin
        ? fields
        : fields.filter(({ Permission }) => Permission !== "None")
      ).map((field, index) => (
        <Stack direction={"row"}>
          <TextField
            key={field.Id}
            label={field.Name.toUpperCase()}
            disabled={isAdmin && field.Permission === "ReadOnly"}
            type={"number"}
            sx={{ maxWidth: "250px" }}
            {...register(`LicenseProfiles.${index}.MaxSessions`, {
              onChange: (e: ChangeEvent<HTMLInputElement>) => {
                const value = processNumericInput(e.target.value);
                if (value === null) return;
                setValue(`LicenseProfiles.${index}.MaxSessions`, value);
              },
            })}
          />
        </Stack>
      ))}
    </Stack>
  );
};

const QuotaLicenses: React.FC<{
  tenantSize: QuotaLicense;
  isAdmin: boolean;
}> = ({ tenantSize, isAdmin }) => {
  const { control, register, setValue } = useFormContext<ProductsFormData>();

  const { fields } = useFieldArray({
    control,
    name: "QuotaLicenses",
  });

  return (
    <Stack direction={"column"} gap={3} flexGrow={1}>
      <Typography variant={"h5"} color={"primary.main"}>
        {"Quota licenses"}
      </Typography>
      <StyledDivider />
      {(isAdmin
        ? fields
        : fields.filter(({ Permission }) => Permission !== "None")
      ).map((field, index) => (
        <TextField
          key={field.Id}
          label={field.Name.toUpperCase()}
          disabled={!isAdmin && field.Permission === "ReadOnly"}
          type={"number"}
          sx={{ maxWidth: "250px" }}
          {...register(`QuotaLicenses.${index}.Quota`, {
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              const value = processNumericInput(e.target.value);
              if (value === null) return;
              setValue(`QuotaLicenses.${index}.Quota`, value);
            },
          })}
        />
      ))}
      <TextField
        disabled
        label={tenantSize.Name.toUpperCase()}
        type={"number"}
        sx={{ maxWidth: "250px" }}
        {...register("TenantSize.Quota")}
      />
    </Stack>
  );
};

const TenantFeatures = ({ isAdmin }: { isAdmin: boolean }) => {
  const { control, register } = useFormContext<ProductsFormData>();

  const { fields } = useFieldArray({
    control,
    name: "TenantFeatures",
  });

  return (
    <Stack direction={"column"} gap={3} flexGrow={1}>
      <Typography variant={"h5"} color={"primary.main"}>
        {"Feature licenses"}
      </Typography>
      <StyledDivider />
      {(isAdmin
        ? fields
        : fields.filter(({ Permission }) => Permission !== "None")
      ).map((license, index) => (
        <Box key={license.Id} display={"flex"} flexDirection={"row"}>
          <Typography flex={1}>{license.Name}</Typography>

          <input
            type={"checkbox"}
            disabled={!isAdmin && license.Permission === "ReadOnly"}
            {...register(`TenantFeatures.${index}.HasLicense`)}
          />
        </Box>
      ))}
    </Stack>
  );
};

type Audit = {
  Id: string;
  Name: string;
  Old: string;
  New: string;
};

const Products = ({ isAdmin }: { isAdmin: boolean }) => {
  const { customerId = "", tenantId = "", partnerId = "" } = useParams();
  const tenantPath = `partners/${partnerId}/customers/${customerId}/tenants/${tenantId}`;
  const formData = useProductsFormData(partnerId, customerId, tenantId);
  const [isSnackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarAudit, setSnackbarAudit] = useState<Audit[]>([]);
  const [errorSaving, setErrorSaving] = useState<boolean>(false);
  const saveButtonRef = useRef(null);

  const methods = useForm<ProductsFormData>({
    mode: "onSubmit",
    values: formData.data || undefined,
  });

  if (!formData) return <></>;

  const onSave: SubmitHandler<ProductsFormData> = async (data) => {
    const dirty = methods.formState.dirtyFields;

    let params: any = {};

    if (dirty.QuotaLicenses) {
      params.QuotaLicenses = data.QuotaLicenses.map(({ Id, Quota }) => ({
        Id,
        Quota,
      }));
    }

    if (dirty.LicenseProfiles) {
      params.LicenseProfiles = data.LicenseProfiles.map(
        ({ Id, MaxSessions }) => ({ Id, MaxSessions })
      );
    }

    if (dirty.TenantFeatures) {
      params.TenantFeatures = data.TenantFeatures.filter(
        ({ HasLicense }) => HasLicense
      ).map(({ Id }) => ({ Id }));
    }

    const log: Audit[] = [
      ...(data.LicenseProfiles || []).map(({ Id, Name, MaxSessions }) => {
        const oldValue = formData.data?.LicenseProfiles.find(
          (old) => old.Id === Id
        )?.MaxSessions;
        if (oldValue === MaxSessions) return null;
        return { Id, Name, Old: oldValue, New: MaxSessions };
      }),
      ...(data.QuotaLicenses || []).map(({ Id, Name, Quota }) => {
        const oldValue = formData.data?.QuotaLicenses.find(
          (change) => change.Id === Id
        )?.Quota;
        if (oldValue === Quota) return null;
        return { Id, Name, Old: oldValue, New: Quota };
      }),
      ...data.TenantFeatures.map(({ Id, Name, HasLicense }) => {
        const oldValue = formData.data?.TenantFeatures.find(
          (change) => change.Id === Id
        )?.HasLicense;
        if (oldValue === HasLicense) return null;
        return {
          Id,
          Name,
          Old: HasLicense ? "Disabled" : "Enabled",
          New: HasLicense ? "Enabled" : "Disabled",
        };
      }),
    ].filter((value) => value !== null) as Audit[];

    setSnackbarOpen(false);
    setErrorSaving(false);
    setSnackbarAudit([]);

    await xhr
      .patch(tenantPath, params)
      .then(() => setSnackbarAudit(log))
      .catch(() => setErrorSaving(true))
      .finally(() => setSnackbarOpen(true));

    await formData.refetch();
  };

  const onError: SubmitErrorHandler<Partial<TenantDetails>> = (errors) => {
    console.log(errors);
  };

  if (formData.data === null) return <>Loading...</>;

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={methods.handleSubmit(onSave, onError)}>
        <Stack direction={"column"} gap={5} marginTop={3}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={5}
            maxWidth={"1700px"}
          >
            <LicenseProfiles isAdmin={isAdmin} />
            <QuotaLicenses
              isAdmin={isAdmin}
              tenantSize={formData.data.TenantSize}
            />
            <TenantFeatures isAdmin={isAdmin} />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"end"}
            marginTop={2}
            marginBottom={1}
            maxWidth={"1700px"}
          >
            <Button
              size={"large"}
              onClick={() => methods.reset()}
              disabled={!methods.formState.isDirty}
              variant={"outlined"}
              sx={{ width: "200px", marginRight: 5 }}
            >
              {"Reset"}
            </Button>
            <Button
              type={"submit"}
              size={"large"}
              ref={saveButtonRef}
              disabled={!methods.formState.isDirty}
              variant={methods.formState.isDirty ? "contained" : "outlined"}
              sx={{ width: "200px" }}
            >
              {"Save"}
            </Button>
          </Box>
        </Stack>
      </form>
      <Snackbar
        open={isSnackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={errorSaving ? "error" : "success"}
        >
          {errorSaving ? (
            <>
              <Typography>{"Error!"}</Typography>
              <Typography>{"The changes could not be saved"}</Typography>
            </>
          ) : (
            <>
              <Typography variant={"h6"}>{"Changes saved!"}</Typography>
              <Table
                size={"small"}
                sx={{
                  marginTop: 1,
                  [`& .${tableCellClasses.root}`]: {
                    padding: 1,
                    borderBottom: "none",
                  },
                }}
              >
                <TableBody>
                  {snackbarAudit.map(({ Id, Name, Old, New }) => (
                    <TableRow key={Id}>
                      <TableCell sx={{ minWidth: "210px" }} align={"left"}>
                        {Name}
                      </TableCell>
                      <TableCell align={"center"}>{Old}</TableCell>
                      <TableCell align={"center"}>{"→"}</TableCell>
                      <TableCell align={"center"}>{New}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </Alert>
      </Snackbar>
    </FormProvider>
  );
};

export default Products;
